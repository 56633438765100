import BigNumber from 'bignumber.js'

export default class Coin {
    constructor(store, defaultBaseDenom) {
		this.defaultBaseDenom = defaultBaseDenom
        this.metadatas = store.getters['cosmos.bank.v1beta1/getDenomsMetadata']({ params: {} }).metadatas
		this.metadata = this.metadatas.find((m) => m.base == defaultBaseDenom)
    }

	denomExponent() {
		let denom = this.metadata.denom_units.find((du) => du.denom == this.metadata.display)
		return denom.exponent
	}

	displayDenom() {
		return this.metadata.display
	}

    toBase(amount) {
		return this.toDenom(amount, this.metadata.display, this.metadata.base)
	}

	toDisplay(amount) {
		return this.toDenom(amount, this.metadata.base, this.metadata.display)
	}

	toDenom(amount, denomFromSymbol, denomToSymbol) {
		let denomFrom = this.metadata.denom_units.find((du) => du.denom == denomFromSymbol)
		let denomTo = this.metadata.denom_units.find((du) => du.denom == denomToSymbol)
		if (denomFrom && denomTo) {
			const precision = BigNumber(10).exponentiatedBy(denomFrom.exponent - denomTo.exponent)
			let a = BigNumber(amount).multipliedBy(precision).toFixed(denomTo.exponent)
			return {
				denom: denomToSymbol,
				amount: a.replace(/(\.[0-9]*[1-9])0+$|\.0*$/, '$1')
			}
		}
		return {
			denom: denomToSymbol,
			amount: 'NaN'
		}
	}
}
