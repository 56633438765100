<template>
  <div
    class="
      absolute
      top-0
      left-0
      right-0
      bottom-0
      z-10
      flex
      items-center
      justify-center
    "
  >
    <div
      class="
        absolute
        top-0
        left-0
        right-0
        bottom-0
        bg-white
        dark:bg-black
        opacity-50
      "
    ></div>
    <div class="w-12 h-12 relative">
      <LoadingIcon icon="ball-triangle" color="white" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>